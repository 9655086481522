.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 20px;

  height: 100%;
  max-width: 500px;
}

.home-page-content {
  top: 0;
  left: 0;
  position: fixed;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 20px;

  width: 100%;
  max-width: 100%;
}
  