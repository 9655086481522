.footer {
  display: flex;
  flex-direction: column;
  flex: 1 0 24px;
  align-items: center;

  padding: 12px 0;
  margin-bottom: 20px;

  font-size: 12px;
}

.footer a {
  color: inherit;
  text-decoration: none;
}

.footer-navigation {
  display: flex;
  justify-content: center;

  padding: 12px 0;
  margin-bottom: 4px;

  font-size: 12px;
}

.footer a {
  text-decoration: none;
}

.footer-navigation__item {
  padding: 0 4px;
}

.footer-copyright {
  padding: 4px 0;
  font-size: 10px;
  color: rgb(47, 53, 58);
}

:root {
  --flatiron_orange: #bc703e;
  --color_paragraph_text: #281e3c;
  --color_date_gray: rgb(85, 85, 85);
}

.page-body {
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;

  font-size: 14px;
  font-family: 'Karla', sans-serif;
}

.page-body a {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid var(--color_paragraph_text);
}

.page-body p {
  line-height: 1.8em;
  color: var(--color_paragraph_text);
}

.page-body h2 {
  margin: 24px 0 16px;
  font-size: 16px;
  font-weight: 600;
}

.page-body h3 {
  margin: 16px 0 8px;
  font-size: 14px;
  font-weight: 600;
}

.page-body img {
  max-width: 100%;
}

.page-body code {
  color: #f14e32;
  background: #eee;
  padding: 2px 4px;
  font-size: 13px;
  font-family: Consolas, monospace, x;
}

.page-body pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 0.8rem;
  line-height: 1.4;
  white-space: pre;
  overflow-x: auto;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 1rem;
}

.page-container {
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 20px;

  height: 100%;
  max-width: 500px;
}

.home-page-content {
  top: 0;
  left: 0;
  position: fixed;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 20px;

  width: 100%;
  max-width: 100%;
}
  
.header {
  padding: 24px 0;
  font-size: 20px;
  font-weight: 500;
}


.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 16px 0;
  height: 320px;
}

input,
textarea {
  border: none;
  outline: none;
  border-radius: 0;
  text-align: center;
  background: none;
  color: black;
  padding: 1rem;
  border: 2px dashed rgba(255, 255, 255, 0);
  box-sizing: border-box;
  cursor: text;
}

input:required,
textarea:required {
  border-bottom: 2px dashed rgba(0, 0, 0, 0.5);
}

.contact-form__submit-button {
  border: none;
}

textarea {
  text-align: left;

  resize: none;
  border-color: rgba(255, 255, 255, 0)
}
textarea:focus {
  background-color: rgba(255, 255, 255, 0.2);
  border: 2px dashed rgba(0, 0, 0, 1);
}
textarea:focus:required:valid {
  border: 2px solid rgba(255, 255, 255, 0);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}
textarea:required:valid {
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}

input {
  border-bottom: 2px dashed rgba(0, 0, 0, 0.5);
}
input:focus {
  border-bottom: 2px dashed rgba(0, 0, 0, 1);
  background-color: rgba(255, 255, 255, 0.2);
}
input:required:valid {
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}
input:required:invalid {
  color: rgba(0, 0, 0, 0.4);
}

::-webkit-input-placeholder {
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  font-style: italic;
}

:-moz-placeholder {
  /* Firefox 18- */

  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  font-style: italic;
}

::-moz-placeholder {
  /* Firefox 19+ */

  text-align: center;
  color: rgba(255, 255, 255, 0.4);
  font-style: italic;
}

:-ms-input-placeholder {
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
  font-style: italic;
}

.expanding {
  vertical-align: top;
}

.send-icn {
  fill: rgba(0, 0, 0, 1);
}

.send-icn:hover {
  fill: rgba(0, 0, 0, 1);
  cursor: pointer;
}

button {
  background: none;
  border: none;
  outline: none;
  margin: 2vmax;
}

button:hover small {
  opacity: 1;
}

small {
  display: block;
  opacity: 0;
}

.background {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;

  height: 100vh;
  width: 100vw;

  overflow: hidden;
  
  z-index: -100;
}

.background > img {
  position: absolute;
  top: 0;
  left: 0;
  
  height: 100%;
  width: 100%;
  overflow: hidden;
  object-fit: cover;

  pointer-events: none;
}

.background-fade-in-exit {
  opacity: 1;
}
.background-fade-in-exit-done {
  opacity: 0;
  transition: opacity 300ms ease-in;
}
.background-fade-in-exit-done {
  opacity: 0;
}

.background-cross-fade-exit {
  opacity: 1;
}
.background-cross-fade-exit-done {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
.background-cross-fade-exit-done {
  opacity: 0;
}

.home-page {
  position: fixed;
  left: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.home-page__body {
  position: relative;
  margin: 8vh 8vw 0 auto;
}

.home-page__title {
  font-size: 52px;
  font-weight: 220;
  color: var(--flatiron_orange);
}

.home-page__footer {
  color: rgb(145, 50, 200);
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

