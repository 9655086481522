@import '../../common/css/constants.css';

.page-body {
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;

  font-size: 14px;
  font-family: 'Karla', sans-serif;
}

.page-body a {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid var(--color_paragraph_text);
}

.page-body p {
  line-height: 1.8em;
  color: var(--color_paragraph_text);
}

.page-body h2 {
  margin: 24px 0 16px;
  font-size: 16px;
  font-weight: 600;
}

.page-body h3 {
  margin: 16px 0 8px;
  font-size: 14px;
  font-weight: 600;
}

.page-body img {
  max-width: 100%;
}

.page-body code {
  color: #f14e32;
  background: #eee;
  padding: 2px 4px;
  font-size: 13px;
  font-family: Consolas, monospace, x;
}

.page-body pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 0.8rem;
  line-height: 1.4;
  white-space: pre;
  overflow-x: auto;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 1rem;
}
