.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 16px 0;
  height: 320px;
}

input,
textarea {
  border: none;
  outline: none;
  border-radius: 0;
  text-align: center;
  background: none;
  color: black;
  padding: 1rem;
  border: 2px dashed rgba(255, 255, 255, 0);
  box-sizing: border-box;
  cursor: text;
}

input:required,
textarea:required {
  border-bottom: 2px dashed rgba(0, 0, 0, 0.5);
}

.contact-form__submit-button {
  border: none;
}

textarea {
  text-align: left;

  resize: none;
  border-color: rgba(255, 255, 255, 0)
}
textarea:focus {
  background-color: rgba(255, 255, 255, 0.2);
  border: 2px dashed rgba(0, 0, 0, 1);
}
textarea:focus:required:valid {
  border: 2px solid rgba(255, 255, 255, 0);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}
textarea:required:valid {
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}

input {
  border-bottom: 2px dashed rgba(0, 0, 0, 0.5);
}
input:focus {
  border-bottom: 2px dashed rgba(0, 0, 0, 1);
  background-color: rgba(255, 255, 255, 0.2);
}
input:required:valid {
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}
input:required:invalid {
  color: rgba(0, 0, 0, 0.4);
}

::-webkit-input-placeholder {
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  font-style: italic;
}

:-moz-placeholder {
  /* Firefox 18- */

  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  font-style: italic;
}

::-moz-placeholder {
  /* Firefox 19+ */

  text-align: center;
  color: rgba(255, 255, 255, 0.4);
  font-style: italic;
}

:-ms-input-placeholder {
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
  font-style: italic;
}

.expanding {
  vertical-align: top;
}

.send-icn {
  fill: rgba(0, 0, 0, 1);
}

.send-icn:hover {
  fill: rgba(0, 0, 0, 1);
  cursor: pointer;
}

button {
  background: none;
  border: none;
  outline: none;
  margin: 2vmax;
}

button:hover small {
  opacity: 1;
}

small {
  display: block;
  opacity: 0;
}
