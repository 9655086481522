.footer {
  display: flex;
  flex-direction: column;
  flex: 1 0 24px;
  align-items: center;

  padding: 12px 0;
  margin-bottom: 20px;

  font-size: 12px;
}

.footer a {
  color: inherit;
  text-decoration: none;
}

.footer-navigation {
  display: flex;
  justify-content: center;

  padding: 12px 0;
  margin-bottom: 4px;

  font-size: 12px;
}

.footer a {
  text-decoration: none;
}

.footer-navigation__item {
  padding: 0 4px;
}

.footer-copyright {
  padding: 4px 0;
  font-size: 10px;
  color: rgb(47, 53, 58);
}
