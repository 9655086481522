@import '../../common/css/constants.css';

.home-page {
  position: fixed;
  left: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.home-page__body {
  position: relative;
  margin: 8vh 8vw 0 auto;
}

.home-page__title {
  font-size: 52px;
  font-weight: 220;
  color: var(--flatiron_orange);
}

.home-page__footer {
  color: rgb(145, 50, 200);
}
