.background {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;

  height: 100vh;
  width: 100vw;

  overflow: hidden;
  
  z-index: -100;
}

.background > img {
  position: absolute;
  top: 0;
  left: 0;
  
  height: 100%;
  width: 100%;
  overflow: hidden;
  object-fit: cover;

  pointer-events: none;
}

.background-fade-in-exit {
  opacity: 1;
}
.background-fade-in-exit-done {
  opacity: 0;
  transition: opacity 300ms ease-in;
}
.background-fade-in-exit-done {
  opacity: 0;
}

.background-cross-fade-exit {
  opacity: 1;
}
.background-cross-fade-exit-done {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
.background-cross-fade-exit-done {
  opacity: 0;
}
